<template>
  <div class="dg-profile__container">
    <div class="dg-profile__panel">
      <transition name="fade" mode="in-out">
        <img class="dg-profile__bg" :src="require(`../images/${currentPackage}_background.svg`)" alt="" />
      </transition>
      <DgAvatar :name="userName" size="x-large" v-if="userName"></DgAvatar>
      <div class="dg-profile__info-container">
        <div class="dg-profile__card">
          <div class="dg-profile__details">
            <div class="dg-profile__details__name">
              {{ companyName }}
            </div>
            <div class="dg-profile__details__package-label" v-if="servicePackage">
              {{ servicePackage }}
            </div>
            <div class="dg-profile__details__info-item" v-if="address">
              <img class="dg-profile__details__info-item__icon" src="../images/address_icon.svg" alt="" />
              <div class="dg-profile__details__info-item__item">
                {{ address }}
              </div>
            </div>
            <div class="dg-profile__details__info-item" v-if="domain">
              <img class="dg-profile__details__info-item__icon" src="../images/domain_icon.svg" alt="" />
              <div class="dg-profile__details__info-item__item domain">
                {{ domain }}
              </div>
            </div>
            <div class="dg-profile__details__info-item" v-if="industry">
              <img class="dg-profile__details__info-item__icon" src="../images/industry_icon.svg" alt="" />
              <div class="dg-profile__details__info-item__item">
                {{ industry }}
              </div>
            </div>
          </div>
          <div class="dg-profile__topic">
            <div class="dg-profile__topic__intro">
              {{ $t("profile.topic_intro") }}
            </div>
            <img src="../images/privacy_label.svg" alt="" v-if="isTopicPrivacy" />
            <img src="../images/infosec_label.svg" alt="" v-else />
          </div>
        </div>
        <DgContentSwitcher class="dg-profile__switcher">
          <DgContentSwitcherTab :name="$t(`profile.dg_service_tab`)">
            <div class="dg-profile__toggler">
              <button
                :class="[
                  `dg-profile__package dg-profile__package--${lineOfBusiness}`,
                  { active: isSelectedPackage(lineOfBusiness) },
                ]"
                @click="selectPackage(lineOfBusiness)"
                v-for="lineOfBusiness in linesOfBusiness"
                :key="lineOfBusiness"
              >
                {{ $t(`profile.packages.${lineOfBusiness}`) }}
              </button>
            </div>
            <HeroSection :selectedPackage="currentPackage"></HeroSection>
            <PricingWidget :selectedPackage="currentPackage"></PricingWidget>
          </DgContentSwitcherTab>
        </DgContentSwitcher>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeroSection from "../components/HeroSection.vue";
import PricingWidget from "../components/PricingWidget.vue";

export default {
  components: { HeroSection, PricingWidget },
  data() {
    return {
      // Note: compliance line fo business is temporarily excluded because the relevant data on crm-service needs to be updated (so as not to break)
      // linesOfBusiness: ["privacy", "infosec", "whistleblowing", "compliance"],
      linesOfBusiness: ["privacy", "infosec", "whistleblowing"],
    };
  },
  computed: {
    ...mapGetters(["companyData", "userData"]),
    currentPackage() {
      if (this.linesOfBusiness.includes(this.$route.params.package)) {
        return this.$route.params.package;
      }
      return this.linesOfBusiness[0];
    },
    isTopicPrivacy() {
      return !this.companyData.ciso;
    },
    userName() {
      return `${this.userData.first_name} ${this.userData.last_name}`;
    },
    companyName() {
      return this.companyData.name;
    },
    servicePackage() {
      return this.companyData.service_package;
    },
    domain() {
      return this.companyData.domain;
    },
    address() {
      return this.companyData.address;
    },
    industry() {
      return this.companyData.industry;
    },
  },
  methods: {
    selectPackage(packageName) {
      this.$router.push({ name: "Profile", params: { package: packageName } });
    },
    isSelectedPackage(lineOfBusiness) {
      return this.currentPackage === lineOfBusiness;
    },
  },
};
</script>

<style lang="scss">
.dg-profile {
  &__container {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  &__panel {
    position: relative;
    max-width: 1040px;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0px 12px 24px rgba(17, 42, 56, 0.15);

    & > .dg-avatar {
      position: absolute;
      left: 35px;
      top: 40px;
    }
  }
  &__bg {
    border-bottom: 1px solid #dcdcdc;
  }
  &__info-container {
    display: flex;
    flex-direction: row;
  }
  &__card {
    width: 248px;
    padding: 8px 16px;
    border-top: 1px solid #dfe4e9;
    border-right: 1px solid #dfe4e9;
    margin-top: 50px;
  }
  &__topic {
    padding-bottom: 12px;
    border-bottom: 1px solid #dfe4e9;
    &__intro {
      color: #7e93a7;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-top: 12px;
    }
  }
  &__details {
    padding-bottom: 7px;
    border-bottom: 1px solid #dfe4e9;
    &__name {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 5px;
    }
    &__package-label {
      background: #e9edef;
      display: inline-block;
      padding: 2px 8px;
      line-height: 20px;
      font-size: 14px;
      color: #003349;
      font-weight: 600;
      margin-bottom: 5px;
    }
    &__info-item {
      display: flex;
      &__icon {
        align-self: flex-start;
        padding: 2px;
      }
      &__item {
        padding: 2px;
        font-size: 12px;
        line-height: 16px;
        margin-left: 2px;
        margin-bottom: 5px;
        &.domain {
          text-decoration: underline;
        }
      }
    }
  }
  &__toggler {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    margin-bottom: 12px;
  }
  &__package {
    padding: 12px 24px;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.3s ease-out;
    opacity: 0.3;
    color: #ffffff;
    border: none;
    text-align: left;
    &:focus {
      outline: none;
    }
    &--whistleblowing,
    &--compliance {
      background: linear-gradient(90deg, #9e2b2f 0%, #cb333b 60.42%, #e03c31 100%);
    }
    &--infosec {
      background: linear-gradient(270deg, #11b7ff 0%, #36b2e8 45.31%, #4182a9 100%);
    }
    &--privacy {
      background: linear-gradient(90deg, #003349 0%, #345569 100%);
    }
    &.active {
      cursor: auto;
      pointer-events: none;
      opacity: 1;
      flex: 1;
      white-space: nowrap;
    }
  }

  &__switcher {
    flex: 1;
    & > .tabs {
      border-bottom: 1px solid #c9d2d7;
      width: 830px;
    }
  }
}
</style>
