<template>
  <div class="dg-pricing">
    <DgSpinner v-if="isLoading" class="dg-widget-spinner" />
    <div v-else>
      <form>
        <div class="dgp-section-row">
          <!-- section 1 (questions section) -->
          <div class="dgp-section dgp-questions">
            <h5>
              <span>1. {{ $t("profile.pricing.infosec.sections.questions.title") }}</span>
            </h5>

            <!-- The contents of the div below (along with the related properties,methods and variables) is added for the case that in the future we may have a industry-dependent logic and UI, similarly to the privacy pricing calculator. -->

            <!-- <div v-if="false" class="dgp-sub-section">
              <span :class="{ 'all-done': industryName }">A</span>
              <ul class="dgp-answers-list dgp-industry-selection shown-lg hidden-xs">
                <li class="dgp-answers-title">
                  <b>{{ $t("profile.pricing.infosec.sections.questions.industry.title") }}:</b>
                </li>
                <li v-for="industry in shownIndustries" :key="industry.key">
                  <label
                    ><input v-model="industryKey" type="radio" name="industry" :value="industry.key" /><span>{{
                      $t(`industries.${industry.key}`)
                    }}</span></label
                  >
                </li>
                <li
                  v-if="!showAllIndustries && shownIndustries.length > maximumNumberOfInitiallyShownIndustries"
                  @click="showAllIndustries = true"
                >
                  <label
                    ><span>{{ $t("show_all_industries") }} ⌄</span></label
                  >
                </li>
              </ul>
              <div class="dgp-answers-dropdown hidden-lg shown-xs">
                <b>{{ $t("profile.pricing.infosec.sections.questions.industry.title") }}:</b>
                <select v-model="industryKey">
                  <option :label="$t('sections.questions.industry.prompt')" value="" selected disabled>
                    {{ $t("profile.pricing.infosec.sections.questions.industry.prompt") }}
                  </option>
                  <option
                    v-for="industry in industryValues"
                    :key="industry.key"
                    :label="$t(`industries.${industry.key}`)"
                    :value="industry.key"
                  >
                    {{ $t(`industries.${industry.key}`) }}
                  </option>
                </select>
              </div>
            </div> -->

            <!-- subsection A -->
            <div class="dgp-sub-section">
              <span
                :class="{
                  'all-done': hasIndustrySpecificQuestions && !hasUnansweredIndustrySpecificQuestions,
                }"
                >A</span
              >
              <b>{{ $t("profile.pricing.infosec.sections.questions.core_questions.title") }}:</b>
              <div v-if="industryKey" class="dgp-core-questions">
                <div
                  v-for="industrySpecificQuestion in industrySpecificQuestions"
                  :key="`${industryKey}-${industrySpecificQuestion.key}`"
                >
                  <h6>
                    {{
                      $t(
                        `profile.pricing.infosec.industry_specific_questions.${industryKey}.${industrySpecificQuestion.key}.question`
                      )
                    }}
                  </h6>
                  <ul class="dgp-answers-list">
                    <li
                      v-for="answerOption in industrySpecificQuestion.answer_options"
                      :key="`${industryKey}-${industrySpecificQuestion.key}-${answerOption.key}`"
                    >
                      <label>
                        <input
                          type="radio"
                          :name="`${industryKey}-${industrySpecificQuestion.key}`"
                          :value="answerOption.key"
                          @change="setIndustrySpecificAnswer(industrySpecificQuestion, answerOption)"
                        />
                        <span
                          ><b></b
                          ><small>{{
                            $t(
                              `profile.pricing.infosec.industry_specific_questions.${industryKey}.${industrySpecificQuestion.key}.answer_options.${answerOption.key}.label`
                            )
                          }}</small></span
                        >
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else class="dgp-core-questions">
                <p>
                  {{ $t("profile.pricing.infosec.sections.questions.core_questions.industry_not_set") }}
                </p>
              </div>
            </div>
            <!-- subsection B -->
            <div class="dgp-sub-section">
              <span :class="{ 'all-done': employees }">B</span>
              <b>{{ $t("profile.pricing.infosec.sections.questions.number_of_employees.title") }}:</b>
              <ul class="dgp-answers-list dgp-employee-count">
                <li v-for="employeesValue in employeesValues" :key="employeesValue.id">
                  <label
                    ><input v-model="employees" type="radio" name="employees" :value="employeesValue.key" /><span
                      ><b></b><small>{{ employeesValue.key }}</small></span
                    ></label
                  >
                </li>
              </ul>
            </div>
            <!-- subsection C -->
            <div v-if="false" class="dgp-sub-section">
              <span :class="{ 'all-done': ismsStatusKey }">D</span>
              <b>{{ $t("profile.pricing.infosec.sections.questions.isms_status.title") }}:</b>
              <div class="dgp-core-questions">
                <div>
                  <h6>
                    {{ $t("profile.pricing.infosec.sections.questions.isms_status.question") }}
                  </h6>
                  <ul class="dgp-answers-list">
                    <li v-for="ismsStatus in ismsStatusValues" :key="ismsStatus.id">
                      <label
                        ><input v-model="ismsStatusKey" type="radio" name="ismsStatus" :value="ismsStatus" /><span
                          ><b></b><small>{{ $t(`profile.pricing.infosec.isms_statuses.${ismsStatus}`) }}</small></span
                        ></label
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="dgp-sub-section">
              <span :class="{ 'all-done': certification }">C</span>
              <b>{{ $t("profile.pricing.infosec.sections.questions.certification.title") }}:</b>
              <div class="dgp-core-questions">
                <div class="certifications-container">
                  <h6>
                    {{ $t("profile.pricing.infosec.sections.questions.certification.question") }}
                  </h6>
                  <ul class="dgp-answers-list">
                    <li v-for="certification in certifications" :key="certification.id">
                      <label
                        ><input
                          type="radio"
                          name="certificationKey"
                          :value="certification.key"
                          v-model="certificationKey"
                        /><span
                          ><b></b
                          ><small>{{ $t(`profile.pricing.infosec.certifications.${certification.key}`) }}</small></span
                        ></label
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- package and answer summary section on the right hand side -->
          <div class="dgp-package">
            <div class="dgp-package-image">
              <img :src="industryImage" :style="industryImageStyle" />
            </div>
            <ul class="dgp-package-information">
              <!-- <li v-if="industryName && false">
                <span>{{ $t("profile.pricing.infosec.sections.questions.summary.industry.title") }}:</span>
                <b>{{ industryName }}</b>
              </li> -->
              <li v-if="industrySpecificAnswersSummaries.length > 0">
                <span>{{ $t("profile.pricing.infosec.sections.questions.summary.core_questions.title") }}:</span>
                <b v-if="industrySpecificAnswersSummaries.length > 0">
                  <ul class="dgp-package-information-core-questions-summary">
                    <li v-for="summary in industrySpecificAnswersSummaries" :key="summary.id">
                      {{ summary }}
                    </li>
                  </ul>
                </b>
                <b v-else>{{ $t("profile.pricing.infosec.sections.questions.summary.core_questions.not_answered") }}</b>
              </li>
              <li v-if="employees">
                <span>{{ $t("profile.pricing.infosec.sections.questions.summary.number_of_employees.title") }}:</span>
                <b>{{ employees }}</b>
              </li>
              <li v-if="ismsStatusKey && false">
                <span>{{ $t(`profile.pricing.infosec.sections.questions.summary.isms_status.title`) }}:</span>
                <b>{{ $t(`profile.pricing.infosec.sections.questions.summary.isms_status.${ismsStatusKey}`) }}</b>
              </li>
              <li v-if="certification">
                <span>{{ $t("profile.pricing.infosec.sections.questions.summary.certification.title") }}:</span>
                <b>{{ $t(`profile.pricing.infosec.certifications.${certification.key}`) }}</b>
              </li>
            </ul>
          </div>
        </div>
        <!-- section 2 (pricing table) -->
        <div class="dgp-section dgp-pricing-section">
          <div class="dgp-sub-section dgp-pricing-sub-section">
            <table class="dgp-pricing-table">
              <thead>
                <tr>
                  <th class="dgp-pricing-inside-heading">
                    <h5>
                      <span>2. {{ $t("profile.pricing.infosec.sections.package.title") }}</span>
                    </h5>
                  </th>
                  <th
                    v-for="bundleType in bundleTypes"
                    :key="bundleType.id"
                    :class="`dgp-pricing-table-bundle-size dgp-pricing-table-bundle-size-${bundleType}`"
                  >
                    <span class="bundle-bg"></span>
                    {{ bundleType }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="dgp-pricing-table-prices">
                  <th scope="row"></th>
                  <td v-for="bundleType in bundleTypes" :key="bundleType.id" class="dgp-pricing-table-bundle-price">
                    <div v-if="valuesSelected && priceFor(bundleType)">
                      <span
                        >{{ currency(priceFor(bundleType))
                        }}<small>
                          /
                          {{ $t("profile.pricing.infosec.pricing_table.month") }}</small
                        ></span
                      >
                      <span
                        ><small>{{ $t("profile.pricing.infosec.pricing_table.plus_one_off_price") }}</small></span
                      >
                    </div>
                    <span v-else-if="valuesSelected">{{
                      $t("profile.pricing.infosec.pricing_table.price_upon_request")
                    }}</span>
                    <span v-else class="dgp-pricing-table-price-indicator">{{
                      $t("profile.pricing.infosec.currency_symbol")
                    }}</span>
                  </td>
                </tr>

                <tr v-if="isCertificationSupportRequest"></tr>
                <tr>
                  <th scope="row">
                    <div class="dgp-pricing-table-th">
                      {{ $t(`profile.pricing.infosec.sections.package.lines.certifications.title`) }}
                      <!-- <InfoBox
                      :text="`profile.pricing.infosec.sections.package.lines.certifications.description`"
                    /> -->
                    </div>
                  </th>
                  <td v-for="bundleType in bundleTypes" :key="bundleType.id">
                    <span v-if="valuesSelected">
                      {{ $t(`profile.pricing.infosec.certifications.${certification.key}`) }}</span
                    >
                    <span v-else class="dgp-text-light">{{
                      $t("profile.pricing.infosec.pricing_table.visible_after_step_1")
                    }}</span>
                  </td>
                </tr>

                <tr v-for="lineItem in lineItems" :key="lineItem.id">
                  <th scope="row">
                    <div class="dgp-pricing-table-th">
                      {{ $t(`profile.pricing.infosec.sections.package.lines.${lineItem}.title`) }}
                      <InfoBox :text="`profile.pricing.infosec.sections.package.lines.${lineItem}.description`" />
                    </div>
                  </th>
                  <td v-for="bundleType in bundleTypes" :key="bundleType.id">
                    <template v-if="isQuotaField(lineItem)">
                      <span v-if="valuesSelected && bundleFor(bundleType)[lineItem]">{{
                        $tc(`profile.pricing.infosec.pricing_table.${lineItem}`, bundleFor(bundleType)[lineItem], {
                          count: formatNumber(bundleType, lineItem),
                        })
                      }}</span>
                      <span v-else-if="valuesSelected">{{
                        $t("profile.pricing.infosec.pricing_table.individual")
                      }}</span>
                      <span class="dgp-text-light" v-else>{{
                        $t("profile.pricing.infosec.pricing_table.visible_after_step_1")
                      }}</span> </template
                    ><template v-else-if="isNumberField(lineItem)">
                      <span v-if="valuesSelected && bundleFor(bundleType)[lineItem]">{{
                        formatNumber(bundleType, lineItem)
                      }}</span>

                      <span v-else-if="valuesSelected">{{
                        $t("profile.pricing.infosec.pricing_table.individual")
                      }}</span>
                      <span v-else class="dgp-text-light">{{
                        $t("profile.pricing.infosec.pricing_table.visible_after_step_1")
                      }}</span>
                    </template>

                    <template v-else-if="isBooleanField(lineItem)">
                      <img :src="imageFor(bundleFor(bundleType)[lineItem])" class="feature-available" />
                    </template>
                    <template v-else>
                      <template
                        v-if="
                          lineItem in bundleFor(bundleType) &&
                          bundleFor(bundleType)[lineItem] !== 'none' &&
                          (!Array.isArray(bundleFor(bundleType)[lineItem]) ||
                            bundleFor(bundleType)[lineItem].length !== 0)
                        "
                      >
                        <ul v-if="Array.isArray(bundleFor(bundleType)[lineItem])">
                          <li v-for="value in bundleFor(bundleType)[lineItem]" :key="value.id">
                            {{ $t(`profile.pricing.infosec.sections.package.lines.${lineItem}.${value}`) }}
                          </li>
                        </ul>
                        <template v-else>
                          {{
                            $t(
                              `profile.pricing.infosec.sections.package.lines.${lineItem}.${
                                bundleFor(bundleType)[lineItem]
                              }`
                            )
                          }}
                        </template>
                      </template>
                      <img v-else :src="imageFor(false)" class="feature-available" />
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InfoBox from "../components/InfoBox.vue";
export default {
  components: {
    InfoBox,
  },

  data() {
    return {
      isLoading: true,
      // NOTE: InfoSec currently only has medium and high Need Scores.
      needScoreValues: ["medium", "high"],
      certifications: [],
      maximumNumberOfInitiallyShownIndustries: 12,
      industryValues: [],
      industryQuestions: {},
      industryQuestionAnswers: {},
      employeesValues: [
        { key: "0-25", crm_id: "0-25" },
        { key: "26-50", crm_id: "26-50" },
        { key: "51-100", crm_id: "51-100" },
        { key: "101-150", crm_id: "101-150" },
        { key: "151-200", crm_id: "151-200" },
        { key: "201-250", crm_id: "201-250" },
        { key: "251-300", crm_id: "251-300" },
        { key: "301-400", crm_id: "301-400" },
        { key: "401-500", crm_id: "401-500" },
        { key: "501-600", crm_id: "501-600" },
        { key: ">600", crm_id: "600+" },
      ],
      bundleTypes: ["light", "essential", "professional"],
      bundles: [],
      certificationKey: "",
      fieldTypes: {},
      showAllIndustries: false,
      selectedNeedScore: "",
      industryKey: "generic", // NOTE: As opposed to Privacy, InfoSec only has generic questions right now.
      employees: "",
      ismsStatusKey: "",
      ismsStatusValues: ["yes", "no"],
      lineItems: [
        "number_of_web_platform_accounts",
        "infosec_trainings",
        "privacy_trainings",
        "consulting_hours_per_year",
        "number_of_infosec_checks",
        "risk_assessment_tool",
        "asset_management_tool",
        "third_party_integrations",
        "documents_customization",
        "enterprise_suite",
        "consulting_hours_transfer",
        "appointment_as_external_officer",
      ],
    };
  },
  watch: {
    valuesSelected(valuesSelected) {
      if (valuesSelected) {
        this.goToStep2();
      }
    },
  },
  async created() {
    await this.fetchIndustryValues();
    await this.fetchCertifications();
    await this.fetchBundles();
    this.isLoading = false;
  },
  computed: {
    valuesSelected() {
      return this.needScore && this.employees && this.certificationKey;
    },
    matchingBundles() {
      return this.bundles.filter(bundle => {
        return (
          bundle.need_score === this.needScore &&
          bundle.minimum_employees === this.minimumEmployees &&
          bundle.maximum_employees === this.maximumEmployees
        );
      });
    },
    minimumEmployees() {
      if (!this.employees) {
        return null;
      }
      return parseInt(this.employees.split("-")[0]);
    },
    maximumEmployees() {
      if (!this.employees) {
        return null;
      }

      return parseInt(this.employees.split("-")[1]);
    },
    industryName() {
      if (!this.industryKey) {
        return;
      }

      return this.$t(`industries.${this.industryKey}`);
    },
    industryImage() {
      return `https://www.dataguard.de/hubfs/Pricing%20Module/package-${this.industryKey || "other"}.png`;
    },
    industryImageStyle() {
      const imgPercentage =
        {
          "0-25": 50,
          "26-50": 55,
          "51-100": 60,
          "101-150": 65,
          "151-200": 70,
          "201-250": 75,
          "251-300": 80,
          "301-400": 85,
          "401-500": 90,
          "501-600": 95,
        }[this.employees] || 100;

      return `width: ${imgPercentage}%;`;
    },
    industrySpecificQuestions() {
      if (!this.industryKey) {
        return [];
      }
      return this.industryQuestions[this.industryKey] || [];
    },
    hasIndustrySpecificQuestions() {
      return this.industrySpecificQuestions.length > 0;
    },
    hasUnansweredIndustrySpecificQuestions() {
      return (
        this.hasIndustrySpecificQuestions && this.industrySpecificAnswers.length < this.industrySpecificQuestions.length
      );
    },
    industrySpecificAnswers() {
      return this.industrySpecificQuestions
        .map(industrySpecificQuestion => {
          const answerOptionKey =
            this.industryQuestionAnswers[this.industryKey] &&
            this.industryQuestionAnswers[this.industryKey][industrySpecificQuestion.key];
          if (!answerOptionKey || !industrySpecificQuestion.answer_options) {
            return;
          }

          const answerOption = industrySpecificQuestion.answer_options.find(
            answerOption => answerOption.key === answerOptionKey
          );
          const summaryText = this.$t(
            `profile.pricing.infosec.industry_specific_questions.${this.industryKey}.${industrySpecificQuestion.key}.answer_options.${answerOption.key}.summary_text`
          );
          return Object.assign({ summary_text: summaryText }, answerOption);
        })
        .filter(answer => answer != null);
    },
    industrySpecificAnswersSummaries() {
      return this.industrySpecificAnswers.map(answer => answer.summary_text);
    },
    certification() {
      return (
        this.certificationKey && this.certifications.find(certification => certification.key === this.certificationKey)
      );
    },
    isCertificationSupportRequest() {
      return this.certificationKey && !["already_certified", "none"].includes(this.certificationKey);
    },
    needScore() {
      if (this.hasIndustrySpecificQuestions) {
        if (!(this.needScoreValue || this.needScoreValue === 0)) {
          return;
        }
        return this.needScoreValue < 34 ? "low" : this.needScoreValue < 67 ? "medium" : "high";
      }
      return this.selectedNeedScore;
    },
    needScoreValue() {
      if (!this.industryKey || this.hasUnansweredIndustrySpecificQuestions) {
        return;
      }

      const needScoreRelevantAnswerOptions = [
        this.industryValues.find(industry => this.industryKey === industry.key),
      ].concat(this.industrySpecificAnswers);

      return needScoreRelevantAnswerOptions.reduce((sum, answerOption) => sum + answerOption.need_score_value, 0);
    },
  },
  methods: {
    goToStep2() {
      // scroll to pricing table when all questions have been answered, but also account for the height of the fixed navbar
      const elementOffset = -64;
      const element = window.document.getElementsByClassName("dgp-pricing-table")[0];
      const y = element.getBoundingClientRect().top + window.pageYOffset + elementOffset;

      window.scrollTo({ behavior: "smooth", top: y });
    },
    isQuotaField(field) {
      return this.isNumberField(field) && this.$te(`profile.pricing.infosec.pricing_table.${field}`);
    },
    isNumberField(field) {
      return ["integer", "float", "currency"].includes(this.fieldTypeFor(field));
    },
    isBooleanField(field) {
      return this.fieldTypeFor(field) === "boolean";
    },
    fieldTypeFor(field) {
      if (!this.fieldTypes) {
        return;
      }

      return this.fieldTypes[field];
    },
    imageFor(value) {
      return `https://www.dataguard.de/hubfs/Pricing%20Module/${value ? "yes" : "no"}.svg`;
    },
    formatNumber(bundleType, field) {
      const bundle = this.bundleFor(bundleType);
      const number = bundle[field];

      switch (this.fieldTypeFor(field)) {
        case "currency":
          return this.currency(number);
        case "float":
          return new Intl.NumberFormat(this.$i18n.locale).format(number);
        default:
          // integer
          return number;
      }
    },
    priceFor(bundleType) {
      const bundle = this.bundleFor(bundleType);

      if (!bundle.mrr) {
        return;
      }

      if (this.certification) {
        return bundle.mrr * this.certification.factor;
      }

      return bundle.mrr;
    },
    currency(price) {
      const formatOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: this.$t("profile.pricing.infosec.currency"),
      };
      return new Intl.NumberFormat(this.$i18n.locale, formatOptions).format(price);
    },

    setIndustrySpecificAnswer(industrySpecificQuestion, answerOption) {
      if (!this.industryKey) {
        return;
      }

      if (!this.industryQuestionAnswers[this.industryKey]) {
        this.$set(this.industryQuestionAnswers, this.industryKey, {});
      }
      this.$set(this.industryQuestionAnswers[this.industryKey], industrySpecificQuestion.key, answerOption.key);
    },
    defaultFeaturesFor(bundleType) {
      //       sizes and that just the quotas are different => we can just select the smallest one and use its values.
      /* eslint-disable */
      const [minimum_employees, maximum_employees] = this.employeesValues[0].key
        .split("-")
        .map(value => parseInt(value));
      const bundle = this.bundles.find(bundle => {
        return (
          bundle.bundle_type == bundleType &&
          bundle.need_score === this.needScoreValues[0] &&
          bundle.minimum_employees === minimum_employees &&
          bundle.maximum_employees === maximum_employees
        );
      });

      if (bundle) {
        return this.lineItems.reduce((features, feature) => {
          if (!(this.isQuotaField(feature) || this.isNumberField(feature))) {
            features[feature] = bundle[feature];
          }

          return features;
          /* eslint-enable */
        }, {});
      }
      return {};
    },
    bundleFor(bundleType) {
      if (!this.valuesSelected) {
        return this.defaultFeaturesFor(bundleType);
      }
      return (
        this.matchingBundles.find(bundle => bundle.bundle_type === bundleType) || this.defaultFeaturesFor(bundleType)
      );
    },

    async fetchBundles() {
      const response = await fetch("https://crm-service.dataguard.de/products/infosec/bundle", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      this.bundles = res.data.map(bundle => bundle.attributes);
      this.fieldTypes = res.meta.field_types;
    },
    async fetchCertifications() {
      const response = await fetch("https://crm-service.dataguard.de/certifications", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      this.certifications = res;
    },
    async fetchIndustryValues() {
      const response = await fetch("https://crm-service.dataguard.de/cns/infosec", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const needScoreJSON = await response.json();
      this.industryValues = needScoreJSON.industries;
      this.industryQuestions = needScoreJSON.industry_specific_questions;
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-widget-spinner {
  justify-content: center;
  display: flex;
  margin-top: 20px;
}

.dg-pricing form {
  max-width: 780;
  margin-top: 24px;
}
.dgp-section-row {
  display: flex;
}

.dgp-section > h5 {
  background-color: #033349;
  color: #ffffff;
  font-weight: 600;
  padding: 0 8px;
  margin: 0;
  position: relative;
  font-size: 16px;
  line-height: 38px;
  height: 38px;
  text-transform: uppercase;
}
.dgp-section > h5:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent #5f7b96 transparent transparent;
}
.dgp-section.dgp-pricing-section {
  display: block;
  margin-bottom: 10px;
}
.dgp-section-row > .dgp-questions > h5 {
  width: 270px;
}

.dgp-sub-section {
  position: relative;
  background: #f2f4f6;
  padding: 4px 24px;
  margin: 6px 0;
  margin-left: 31px;
  width: 500px;
}

.dgp-sub-section > span {
  position: absolute;
  color: #ffffff;
  background-color: #7e93a7;
  display: block;
  width: 24px;
  height: 24px;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  top: 12px;
  left: -12px;
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: center center;
}
.dgp-sub-section > span.all-done {
  background-color: #5bc88d;
}

.dgp-sub-section > b {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

.dgp-core-questions h6 {
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  margin: 24px 0 8px 0;
}
.dgp-core-questions p {
  font-size: 16px;
}

.dgp-core-questions > p {
  margin: 0;
  font-size: 14px;
  color: #7e93a7;
}

.dgp-core-questions > div {
  background-color: #dfe4e9;
  margin-bottom: 8px;
  padding: 6px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dgp-core-questions > div > h6 {
  font-size: 14px;
  margin: 0 14px 0 0;
  position: relative;
  line-height: 20px;
  white-space: pre-line;
  margin-right: 6px;
}

.dgp-core-questions > .certifications-container > h6 {
  flex-basis: 25%;
}

.dgp-answers-list > li {
  display: inline-block;
  margin: 0 2px;
}

.dgp-package-information ul {
  font-weight: bold;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dgp-pricing-table ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dgp-core-questions > div > ul {
  margin-bottom: 0;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.dgp-answers-list > li > label {
  display: block;
  position: relative;
  cursor: pointer;
  margin: 0;
}
.dgp-pricing-table-bundle-size .bundle-bg {
  display: block;
  margin-bottom: 6px;
  margin-top: 22px;
  width: 165px;
  height: 20px;
}
.dgp-pricing-table-bundle-price span {
  display: block;
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
}
.dgp-text-light {
  color: #7e93a7;
}
.dgp-answers-list > li > label > span {
  background-color: #dfe4e9;
  display: block;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  color: #0b344b;
}

.dgp-answers-list small {
  font-size: 12px;
}

.dgp-employee-count > li > label > input ~ span {
  position: relative;
  background-color: transparent;
  color: #0b344b;
  transition: 0.25s;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
}
.dgp-core-questions > div > ul > li > label > span {
  background-color: #cbd4dc;
  padding: 3px 5px;
  font-size: 14px;
}
.dgp-pricing-table-bundle-size-light .bundle-bg {
  background-color: #0d2937;
}
.dgp-pricing-table-bundle-size-essential .bundle-bg {
  background-color: #7e93a7;
}
.dgp-pricing-table-bundle-size-professional .bundle-bg {
  background-color: #36b2e8;
}

.dgp-answers-list.dgp-employee-count > li > label > span {
  padding: 4px 2px;
}

.dgp-answers-list > li > label > input:checked ~ span {
  background-color: #0b344b;
  color: #ffffff;
}
.dgp-employee-count > li > label > input:checked ~ span {
  background-color: transparent;
  color: #0b344b;
}
.dgp-section-row > .dgp-package .dgp-package-information > li span {
  display: block;
  text-transform: uppercase;
}
.dgp-employee-count > li > label > input:not(:checked):hover ~ span {
  background-color: transparent;
  color: #0b344b;
}
.dgp-core-questions > div > ul > li > label > input:not(:checked) ~ span:hover {
  background-color: #bbc6d0 !important;
}

.dgp-employee-count > li {
  margin: 0;
}

.dgp-pricing-table li {
  display: inline;
}
.dgp-pricing-table li:after {
  content: " & ";
}
.dgp-pricing-table li:last-of-type:after {
  content: "";
}
.dgp-package-information ul li {
  margin: 0;
  padding: 0;
}
.dgp-core-questions > div > ul > li {
  margin: 0 0 7px 0;
  border-right: 2px #dfe4e9 solid;
  float: left;
  line-height: 16px;
}
.dgp-core-questions > div > ul > li:last-child {
  border-right: 2px #dfe4e9 solid;
  border-right: 0;
}

.dgp-core-questions > div > ul > li label {
  margin: 0;
}

.dgp-employee-count > li > label > input ~ span:before {
  content: "";
  position: absolute;
  display: none;
  width: 100%;
  height: 1px;
  background-color: #7e93a7;
  top: 6px;
  left: 0;
  z-index: 0;
}
.dgp-employee-count > li:first-child > label > input ~ span:before {
  width: 50% !important;
  left: 50% !important;
}
.dgp-employee-count > li:last-child > label > input ~ span:before {
  width: 50% !important;
}

.dgp-answers-list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
  flex-wrap: wrap;
}

.dgp-answers-dropdown > b {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.dgp-employee-count > li > label > input ~ span > b {
  display: none;
  background-color: #7e93a7;
  width: 5px;
  height: 5px;
  border-radius: 12px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  transition: 0.25s;
}
.dgp-answers-list > li.dgp-answers-title b {
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.dgp-answers-list > li > label > input {
  z-index: 2;
  opacity: 0;
  position: absolute;
}

.dgp-employee-count {
  position: relative;
}

.dgp-pricing-table-bundle-price small {
  font-weight: normal;
  font-size: 14px;
}
.dgp-employee-count > li > label > input ~ span > small {
  opacity: 1;
  transition: 0.25s;
  display: block;
  text-align: center;
  background-color: #dfe4e9;
  padding: 0 6px;
  font-size: 12px;
}

.dgp-employee-count > li:first-child > label > input ~ span > small,
.dgp-employee-count > li:last-child > label > input ~ span > small {
  opacity: 1;
}

.dgp-employee-count > li > label > input:checked ~ span > small {
  opacity: 1;
  background-color: #0b344b;
  color: #ffffff;
}
.dgp-employee-count > li > label > input:checked ~ span > b {
  background-color: #e03c31;
  box-shadow: 0 0 0 2px #e03c31, 0 0 0 6px #cbd4dc;
  position: relative;
  z-index: 2;
}
.dgp-section-row > .dgp-package .dgp-package-information > li b {
  font-size: 14px;
  text-transform: uppercase;
}
.dgp-employee-count > li > label > input:not(:checked):hover ~ span > small {
  background-color: #cbd4dc;
}

.dgp-employee-count > li > label > input:not(:checked):hover ~ span > b {
  background-color: #e03c31;
  box-shadow: 0 0 0 2px #e03c31, 0 0 0 6px #cbd4dc;
  position: relative;
  z-index: 2;
}
.dgp-section-row > .dgp-package {
  width: 40%;
  max-width: 540px;
  padding: 12px;
}

.dgp-section-row > .dgp-package .dgp-package-image {
  width: 200px;
  height: 140px;
  position: relative;
  margin-bottom: 12px;
}
.dgp-pricing-table tbody td img {
  max-height: 16px;
  display: inline-block;
  vertical-align: middle;
}
.dgp-section-row > .dgp-package img {
  max-width: 100%;
  transition: 0.35s;
  -webkit-transition: 0.35s;
  -moz-transition: 0.35s;
  position: absolute;
  left: 40;
  bottom: 0;
}

.dgp-section-row > .dgp-package .dgp-package-information {
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.dgp-section-row > .dgp-package .dgp-package-information li {
  margin-bottom: 20px;
}

.dgp-pricing-sub-section {
  background: transparent;
  padding: 0;
  margin: 0;
}

.dgp-pricing-table {
  border: 0;
  border-spacing: 2px;
  font-size: 14px;
  table-layout: fixed;
  border-collapse: separate;
}
.dgp-pricing-table-prices th,
.dgp-pricing-table-prices td {
  background-color: transparent !important;
}

.dgp-pricing-table thead th,
.dgp-pricing-table tbody td {
  text-align: center;
}

.dgp-pricing-table thead th {
  background-color: transparent;
  border: 0;
}

.dgp-pricing-table th.dgp-pricing-inside-heading {
  text-align: left;
  padding: 0;
  vertical-align: top;
}

.dgp-pricing-table th.dgp-pricing-inside-heading h5:not(.no-triangle-pls):after {
  content: "";
  position: absolute;
  left: 10px;
  bottom: -100px;
  height: 100px;
  width: 1px;
  background-color: #5f7b96;
  opacity: 0.5;
  display: none;
}
.dgp-pricing-table th.dgp-pricing-inside-heading h5:not(.no-triangle-pls):before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent #5f7b96 transparent transparent;
}
.dgp-pricing-table th.dgp-pricing-inside-heading h5 {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  position: relative;
  font-size: 16px;
  width: 270px;
  background-color: #033349;
  line-height: 42px;
  padding: 0 8px;
}
.dgp-pricing-table tbody td {
  background-color: #f2f4f6;
  border: 0;
  font-size: 14px;
  font-weight: bold;
  padding: 2px 4px;
}
.dgp-pricing-table tbody .dgp-pricing-last-row td {
  background-color: transparent;
  padding: 0;
}
.dgp-pricing-table tbody th {
  border: 0;
  border-bottom: 1px #dcdcdc solid;
  position: relative;
  font-weight: normal !important;
  text-align: left;
}
.dgp-pricing-table tbody th .dgp-pricing-table-th {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  line-height: 20px;
}
.dgp-pricing-table tbody th .dgp-pricing-table-th .dgp-infobox {
  margin-left: 6px;
}
.dgp-pricing-table tbody .dgp-pricing-last-row th {
  border: 0;
  padding: 0;
}
.dgp-pricing-table-bundle-size,
.dgp-pricing-table-bundle-price {
  text-align: center;
}

.dgp-pricing-table-bundle-size {
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
}

.dgp-pricing-table-bundle-size-light {
  color: #0d2937;
}

.dgp-pricing-table-bundle-size-essential {
  color: #7e93a7;
}

.dgp-pricing-table-bundle-size-professional {
  color: #36b2e8;
}

.dgp-pricing-table-bundle-price {
  font-size: 14px;
  font-weight: bold;
}

.dgp-pricing-table-bundle-price .dgp-pricing-table-price-indicator {
  font-size: 24px;
  line-height: 29px;
}

.dgp-pricing-table th.dgp-pricing-inside-heading h5.no-triangle-pls {
  margin-top: 42px;
}
</style>
