export default {
  methods: {
    translate(key) {
      return this.$t(`${this.translationScope}.${key}`);
    },
    pluralize(key, options) {
      return this.$tc(`${this.translationScope}.${key}`, options);
    },
  },
};
