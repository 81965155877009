<template>
  <div class="dg-profile__hero">
    <img
      class="dg-profile__hero__pic"
      :src="CSMImageURL"
      :alt="$t('profile.hero_box.descriptive_text.csm_image')"
      @error="onCSMImageError"
      v-if="CSMImage"
    />
    <img
      class="dg-profile__hero__pic"
      :src="leadConsultantImage"
      :alt="$t('profile.hero_box.descriptive_text.lead_consultant_image')"
      @error="onLeadConsultantImageError"
      v-else
    />
    <div class="dg-profile__hero__content">
      <h3 class="dg-profile__hero__content__header">
        {{ $t(`profile.hero_box.${selectedPackage}.title`) }}
      </h3>
      <p class="dg-profile__hero__content__msg" v-html="$t(`profile.hero_box.${selectedPackage}.msg`)"></p>
      <span
        class="dg-profile__hero__content__list"
        v-html="$t(`profile.hero_box.${selectedPackage}.list_items`)"
      ></span>
      <div class="dg-profile__hero__content__buttons">
        <DgButton class="book-btn" variantStyle="secondary" @click="goToBookingsLink">{{
          $t("profile.hero_box.book_btn")
        }}</DgButton>
        <DgButton class="dwnl-btn" variantStyle="secondary" @click="downloadInfoMaterial" v-if="hasInfoMaterial">
          <img class="dwnl-btn-arrow" src="../images/arrow-right.svg" alt="" />
          {{ $t("profile.hero_box.download_btn") }}</DgButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { featureLinkMixin } from "@/engines/common/mixins";

export default {
  mixins: [featureLinkMixin],
  props: {
    selectedPackage: {
      type: String,
      validator: value => ["privacy", "infosec", "whistleblowing", "compliance"].includes(value),
    },
  },
  computed: {
    ...mapGetters(["companyData"]),
    CSMImage() {
      return this.companyData.customer_success_manager.image;
    },
    CSMImageURL() {
      return this.companyData.customer_success_manager.image.url;
    },
    leadConsultantImage() {
      if (this.companyData.main_contact_dpo) return `data:image/png;base64, ${this.companyData.main_contact_dpo.image}`;
      return `require("/img/Guardette.png")`;
    },
    getPackageBookingUrl() {
      return this.featureURL(`booking_${this.selectedPackage}`);
    },
    hasInfoMaterial() {
      return !!this.$t(`profile.hero_box.${this.selectedPackage}.info_material`);
    },
  },
  methods: {
    goToBookingsLink() {
      let url = this.getPackageBookingUrl;
      window.open(url, "_blank");
    },
    downloadInfoMaterial() {
      if (!this.hasInfoMaterial) {
        return;
      }

      const locale = this.$route.params.lang;
      const infoMaterial = this.$t(`profile.hero_box.${this.selectedPackage}.info_material`);

      const link = document.createElement("a");
      link.href = infoMaterial.url;
      link.download = infoMaterial.title;
      link.target = "_blank";
      link.click();
    },
    onCSMImageError(e) {
      const fallBackImage = "/img/Guardette.png";
      e.target.src = this.leadConsultantImage || fallBackImage;
    },
    onLeadConsultantImageError(e) {
      const fallBackImage = "/img/Guardette.png";
      e.target.src = fallBackImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-profile {
  &__hero {
    display: flex;
    border: 1px solid #dfe4e9;
    &__pic {
      flex-basis: 25%;
      object-fit: cover;
      width: 159px;
      height: 283px;
    }
    &__content {
      margin: 12px 24px;
      width: 570px;
      &__header {
        text-transform: uppercase;
      }
      &__header,
      &__msg,
      &__list {
        margin-bottom: 5px;
      }
      &__buttons {
        display: flex;
        .book-btn {
          padding: 8px 12px;
          margin-right: 10px;
        }
        .dwnl-btn {
          background: #7e93a7;
          padding: 8px 12px;
          &-arrow {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
