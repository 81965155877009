<template>
  <div v-if="translationAvailable" class="dgp-infobox">
    <img class="dgp-infobox__icon" src="https://www.dataguard.de/hubfs/Pricing%20Module/info.svg" />
    <div class="dgp-infobox__content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    translationAvailable() {
      return this.$t(this.text) !== this.text;
    },
    content() {
      return this.$t(this.text);
    },
    assignClass() {
      return {
        "dgp-infobox-core-questions": this.position === "coreQuestions",
        "dgp-infobox-pricing-table": this.position === "pricingTable",
      };
    },
  },
  props: {
    text: String,
    position: {
      type: String,
      validator: value => ["coreQuestions", "pricingTable"].includes(value),
      default: "pricingTable",
    },
  },
};
</script>

<style lang="scss">
.dgp-infobox {
  position: relative;
  display: flex;
  flex-shrink: 0;

  &__content {
    position: absolute;
    line-height: 18px;
    margin: 0;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all ease;
    padding: 12px;
    width: 200px;
    border-radius: 5px;
    color: #0b344b;
    word-break: break-word;
    hyphens: auto;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 16px 0px;
    bottom: calc(100% + 4px);
    z-index: 1;
  }

  &__icon {
    cursor: pointer;
    width: 16px;
    vertical-align: top;

    &:hover {
      & ~ .dgp-infobox__content {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
</style>
