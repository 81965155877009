<template>
  <div class="dg-pricing">
    <DgSpinner v-if="isLoading" class="dg-widget-spinner" />
    <div v-else>
      <form>
        <div class="dgp-section-row">
          <!-- section 1 (questions section) -->
          <div class="dgp-section dgp-questions">
            <h5>
              <span>1. {{ translate("sections.questions.title") }}</span>
            </h5>
            <div class="dgp-sub-section">
              <span :class="{ 'all-done': employees }">A</span>
              <b>{{ translate("sections.questions.number_of_employees.title") }}:</b>
              <ul class="dgp-answers-list dgp-employee-count">
                <li v-for="employeesValue in employeesValues" :key="employeesValue.id">
                  <label
                    ><input type="radio" name="employees" :value="employeesValue.key" v-model="employees" /><span
                      ><b></b><small>{{ employeesValue.key }}</small></span
                    ></label
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- section 2 (pricing table) -->
        <div class="dgp-section dgp-pricing-section">
          <div class="dgp-sub-section dgp-pricing-sub-section">
            <table class="dgp-pricing-table">
              <thead>
                <tr>
                  <th class="dgp-pricing-inside-heading">
                    <h5>
                      <span>2. {{ translate("sections.package.title") }}</span>
                    </h5>
                  </th>
                  <th
                    v-for="bundleType in bundleTypes"
                    :key="bundleType.id"
                    :class="`dgp-pricing-table-bundle-size dgp-pricing-table-bundle-size-${bundleType}`"
                  >
                    <span class="bundle-bg"></span>
                    {{ bundleType }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="dgp-pricing-table-prices">
                  <th scope="row"></th>
                  <td class="dgp-pricing-table-bundle-price" v-for="bundleType in bundleTypes" :key="bundleType.id">
                    <div v-if="valuesSelected && bundleFor(bundleType).mrr">
                      <span
                        >{{ currency(bundleFor(bundleType).mrr)
                        }}<small> / {{ translate("pricing_table.month") }}</small></span
                      >
                      <span>
                        <small>{{ translate("pricing_table.plus_one_off_price") }}</small>
                      </span>
                    </div>
                    <span v-else-if="valuesSelected">{{ translate("pricing_table.price_upon_request") }}</span>
                    <span class="dgp-pricing-table-price-indicator" v-else>{{ pricingIndicatorFor(bundleType) }}</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="dgp-pricing-table-th">
                      {{ translate("sections.package.lines.basic_services.title") }}
                      <InfoBox :text="`${translationScope}.sections.package.lines.basic_services.description`" />
                    </div>
                  </th>
                  <td v-for="bundleType in bundleTypes" :key="bundleType.id">
                    <img :src="imageFor(true)" class="feature-available" />
                  </td>
                </tr>
                <tr v-for="quota in ['number_of_web_platform_accounts', 'consulting_hours_per_year']" :key="quota">
                  <th scope="row">
                    <div class="dgp-pricing-table-th">
                      {{ translate(`sections.package.lines.${quota}.title`) }}
                      <InfoBox :text="`${translationScope}.sections.package.lines.${quota}.description`" />
                    </div>
                  </th>
                  <td v-for="bundleType in bundleTypes" :key="bundleType.id">
                    <span
                      v-if="valuesSelected && hasPrice(bundleFor(bundleType)) && !isNaN(bundleFor(bundleType)[quota])"
                    >
                      {{ pluralize(`pricing_table.${quota}`, bundleFor(bundleType)[quota]) }}
                    </span>
                    <span v-else-if="valuesSelected">{{ translate("pricing_table.individual") }}</span>
                    <span class="dgp-text-light" v-else>{{ translate("pricing_table.visible_after_step_1") }}</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="dgp-pricing-table-th">
                      {{ translate(`sections.package.lines.whistleblowing_type.title`) }}
                      <InfoBox :text="`${translationScope}.sections.package.lines.whistleblowing_type.description`" />
                    </div>
                  </th>
                  <td v-for="bundleType in bundleTypes" :key="bundleType.id">
                    <span class="is-capitalized">{{ bundleFor(bundleType).whistleblowing_type }}</span>
                  </td>
                </tr>
                <tr
                  v-for="(quota, feature) in {
                    compliance_checks: 'number_of_compliance_checks',
                    compliance_partner_management: 'number_of_compliance_partners',
                  }"
                  :key="feature"
                >
                  <th scope="row">
                    <div class="dgp-pricing-table-th">
                      {{ translate(`sections.package.lines.${feature}.title`) }}
                      <InfoBox :text="`${translationScope}.sections.package.lines.${feature}.description`" />
                    </div>
                  </th>
                  <td v-for="bundleType in bundleTypes" :key="bundleType.id">
                    <template v-if="bundleFor(bundleType)[feature]">
                      <span v-if="valuesSelected && bundleFor(bundleType)[quota]">{{
                        pluralize(`pricing_table.${quota}`, bundleFor(bundleType)[quota])
                      }}</span>
                      <span v-else-if="valuesSelected">{{ translate("pricing_table.individual") }}</span>
                      <span class="dgp-text-light" v-else>{{ translate("pricing_table.visible_after_step_1") }}</span>
                    </template>
                    <img :src="imageFor(bundleFor(bundleType)[feature])" class="feature-available" v-else />
                  </td>
                </tr>
                <tr v-for="feature in features" :key="feature">
                  <th scope="row">
                    <div class="dgp-pricing-table-th">
                      {{ translate(`sections.package.lines.${feature}.title`) }}
                      <InfoBox :text="`${translationScope}.sections.package.lines.${feature}.description`" />
                    </div>
                  </th>
                  <td v-for="bundleType in bundleTypes" :key="bundleType.id">
                    <img :src="imageFor(bundleFor(bundleType)[feature])" class="feature-available" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="dgp-pricing-table-th">
                      {{ translate("sections.package.lines.consulting_hours_transfer.title") }}
                      <InfoBox
                        :text="`${translationScope}.sections.package.lines.consulting_hours_transfer.description`"
                      />
                    </div>
                  </th>
                  <td v-for="bundleType in bundleTypes" :key="bundleType.id">
                    <template v-if="bundleFor(bundleType).consulting_hours_transfer !== 'none'">
                      {{
                        translate(
                          `sections.package.lines.consulting_hours_transfer.${
                            bundleFor(bundleType).consulting_hours_transfer
                          }`
                        )
                      }}
                    </template>
                    <img :src="imageFor(false)" class="feature-available" v-else />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InfoBox from "../components/InfoBox.vue";
import TranslationHelpers from "../mixins/translation_helpers";

export default {
  mixins: [TranslationHelpers],
  components: {
    InfoBox,
  },

  data() {
    return {
      isLoading: true,
      translationScope: "profile.pricing.compliance",
      employeesValues: [
        { key: "0-25", crm_id: "0-25" },
        { key: "26-50", crm_id: "26-50" },
        { key: "51-100", crm_id: "51-100" },
        { key: "101-150", crm_id: "101-150" },
        { key: "151-200", crm_id: "151-200" },
        { key: "201-250", crm_id: "201-250" },
        { key: "251-300", crm_id: "251-300" },
        { key: "301-400", crm_id: "301-400" },
        { key: "401-500", crm_id: "401-500" },
        { key: "501-600", crm_id: "501-600" },
        { key: ">600", crm_id: "600+" },
      ],
      bundleTypes: ["light", "essential", "professional"],
      bundles: [],
      employees: "",
      features: ["platform_features_early_access", "dedicated_customer_success_manager"],
    };
  },
  created() {
    this.fetchBundles();
  },
  computed: {
    valuesSelected() {
      return this.employees;
    },
    matchingBundles() {
      return this.bundles.filter(bundle => {
        return bundle.minimum_employees === this.minimumEmployees && bundle.maximum_employees === this.maximumEmployees;
      });
    },

    minimumEmployees() {
      if (!this.employees) {
        return null;
      }
      return parseInt(this.employees.split("-")[0]);
    },
    maximumEmployees() {
      if (!this.employees) {
        return null;
      }

      return parseInt(this.employees.split("-")[1]);
    },
  },
  methods: {
    imageFor(value) {
      return `https://www.dataguard.de/hubfs/Pricing%20Module/${value ? "yes" : "no"}.svg`;
    },
    currency(price) {
      const formatOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: this.translate("currency"),
      };
      return new Intl.NumberFormat(this.$i18n.locale, formatOptions).format(price);
    },
    defaultFeaturesFor(bundleType) {
      const bundle = this.bundles.find(bundle => bundle.bundle_type == bundleType);
      const features = [
        "compliance_checks",
        "compliance_partner_management",
        "whistleblowing_type",
        "platform_features_early_access",
        "dedicated_customer_success_manager",
        "consulting_hours_transfer",
      ];
      return features.reduce((features, feature) => {
        features[feature] = bundle[feature];
        return features;
      }, {});
    },
    bundleFor(bundleType) {
      if (!this.valuesSelected) {
        return this.defaultFeaturesFor(bundleType);
      }
      return (
        // A bundle only counts as such if it matches and if it has an MRR. Otherwise we want to fall back to the
        // default values (relevant for quota fields like number of compliance checks).
        this.matchingBundles.find(bundle => bundle.bundle_type === bundleType && bundle.mrr) ||
        this.defaultFeaturesFor(bundleType)
      );
    },
    pricingIndicatorFor(bundleType) {
      return Array(this.bundleTypes.indexOf(bundleType) + 1)
        .fill(this.translate("currency_symbol"), 0)
        .join("");
    },
    hasPrice(bundle) {
      return bundle.mrr && bundle.mrr > 0;
    },
    async fetchBundles() {
      try {
        const response = await fetch("https://crm-service.dataguard.de/products/compliance/bundle", {
          headers: { Accept: "application/json", "Content-Type": "application/json" },
        });
        const res = await response.json();
        this.bundles = res.data.map(bundle => bundle.attributes);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-widget-spinner {
  justify-content: center;
  display: flex;
  margin-top: 20px;
}

.dg-pricing form {
  max-width: 780;
  margin-top: 24px;
}
.dgp-section-row {
  display: flex;
}

.dgp-section > h5 {
  background-color: #033349;
  color: #ffffff;
  font-weight: 600;
  padding: 0 8px;
  margin: 0;
  position: relative;
  font-size: 16px;
  line-height: 38px;
  height: 38px;
  text-transform: uppercase;
}
.dgp-section > h5:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent #5f7b96 transparent transparent;
}
.dgp-section.dgp-pricing-section {
  display: block;
  margin-bottom: 10px;
}
.dgp-section-row > .dgp-questions > h5 {
  width: 301px;
}

.dgp-sub-section {
  position: relative;
  background: #f2f4f6;
  padding: 4px 24px;
  margin: 6px 0;
  margin-left: 31px;
}

.dgp-sub-section > span {
  position: absolute;
  color: #ffffff;
  background-color: #7e93a7;
  display: block;
  width: 24px;
  height: 24px;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  top: 12px;
  left: -12px;
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: center center;
}
.dgp-sub-section > span.all-done {
  background-color: #5bc88d;
}

.dgp-sub-section > b {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

.dgp-answers-list > li {
  display: inline-block;
  margin: 0 2px;
}

.dgp-answers-list > li > label {
  display: block;
  position: relative;
  cursor: pointer;
  margin: 0;
}
.dgp-pricing-table-bundle-size .bundle-bg {
  display: block;
  margin-bottom: 6px;
  margin-top: 22px;
  width: 165px;
  height: 20px;
}
.dgp-pricing-table-bundle-price span {
  display: block;
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
}

.dgp-answers-list > li > label > span {
  background-color: #dfe4e9;
  display: block;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  color: #0b344b;
}

.dgp-answers-list small {
  font-size: 12px;
}

.dgp-employee-count > li > label > input ~ span {
  position: relative;
  background-color: transparent;
  color: #0b344b;
  transition: 0.25s;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
}

.dgp-pricing-table-bundle-size-light .bundle-bg {
  background-color: #7e93a7;
}
.dgp-pricing-table-bundle-size-essential .bundle-bg {
  background-color: #e03c31;
}
.dgp-pricing-table-bundle-size-professional .bundle-bg {
  background-color: #b12f34;
}

.dgp-answers-list.dgp-employee-count > li > label > span {
  padding: 4px 2px;
}

.dgp-answers-list > li > label > input:checked ~ span {
  background-color: #0b344b;
  color: #ffffff;
}
.dgp-employee-count > li > label > input:checked ~ span {
  background-color: transparent;
  color: #0b344b;
}
.dgp-employee-count > li > label > input:not(:checked):hover ~ span {
  background-color: transparent;
  color: #0b344b;
}

.dgp-employee-count > li {
  margin: 0;
}

.dgp-employee-count > li > label > input ~ span:before {
  content: "";
  position: absolute;
  display: none;
  width: 100%;
  height: 1px;
  background-color: #7e93a7;
  top: 6px;
  left: 0;
  z-index: 0;
}
.dgp-employee-count > li:first-child > label > input ~ span:before {
  width: 50% !important;
  left: 50% !important;
}
.dgp-employee-count > li:last-child > label > input ~ span:before {
  width: 50% !important;
}

.dgp-answers-list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
}

.dgp-answers-dropdown > b {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.dgp-employee-count > li > label > input ~ span > b {
  display: none;
  background-color: #7e93a7;
  width: 5px;
  height: 5px;
  border-radius: 12px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  transition: 0.25s;
}

.dgp-answers-list > li > label > input {
  z-index: 2;
  opacity: 0;
  position: absolute;
}

.dgp-employee-count {
  position: relative;
}

.dgp-pricing-table-bundle-price small {
  font-weight: normal;
  font-size: 14px;
}
.dgp-employee-count > li > label > input ~ span > small {
  opacity: 1;
  transition: 0.25s;
  display: block;
  text-align: center;
  background-color: #dfe4e9;
  padding: 0 6px;
  font-size: 12px;
}

.dgp-employee-count > li:first-child > label > input ~ span > small,
.dgp-employee-count > li:last-child > label > input ~ span > small {
  opacity: 1;
}

.dgp-employee-count > li > label > input:checked ~ span > small {
  opacity: 1;
  background-color: #0b344b;
  color: #ffffff;
}
.dgp-employee-count > li > label > input:checked ~ span > b {
  background-color: #e03c31;
  box-shadow: 0 0 0 2px #e03c31, 0 0 0 6px #cbd4dc;
  position: relative;
  z-index: 2;
}
.dgp-employee-count > li > label > input:not(:checked):hover ~ span > small {
  background-color: #cbd4dc;
}

.dgp-employee-count > li > label > input:not(:checked):hover ~ span > b {
  background-color: #e03c31;
  box-shadow: 0 0 0 2px #e03c31, 0 0 0 6px #cbd4dc;
  position: relative;
  z-index: 2;
}
.dgp-pricing-table tbody td img {
  max-height: 16px;
  display: inline-block;
  vertical-align: middle;
}

.dgp-pricing-sub-section {
  background: transparent;
  padding: 0;
  margin: 0;
}

.dgp-pricing-table {
  border: 0;
  border-spacing: 2px;
  font-size: 14px;
  table-layout: fixed;
  border-collapse: separate;
}
.dgp-pricing-table-prices th,
.dgp-pricing-table-prices td {
  background-color: transparent !important;
}
.dgp-pricing-table thead th,
.dgp-pricing-table tbody td {
  text-align: center;
}

.dgp-pricing-table thead th {
  background-color: transparent;
  border: 0;
}

.dgp-pricing-table th.dgp-pricing-inside-heading {
  text-align: left;
  padding: 0;
  vertical-align: top;
}

.dgp-pricing-table th.dgp-pricing-inside-heading h5 {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  position: relative;
  font-size: 16px;
  background-color: #033349;
  line-height: 42px;
  padding: 0 8px;
}
.dgp-pricing-table tbody td {
  background-color: #f2f4f6;
  border: 0;
  font-size: 14px;
  font-weight: bold;
  padding: 2px 4px;
}
.dgp-pricing-table tbody .dgp-pricing-last-row td {
  background-color: transparent;
  padding: 0;
}
.dgp-pricing-table tbody th {
  border: 0;
  border-bottom: 1px #dcdcdc solid;
  position: relative;
  font-weight: normal !important;
  text-align: left;
}
.dgp-pricing-table tbody th .dgp-pricing-table-th {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  line-height: 20px;
}
.dgp-pricing-table tbody th .dgp-pricing-table-th .dgp-infobox {
  margin-left: 6px;
}
.dgp-pricing-table tbody .dgp-pricing-last-row th {
  border: 0;
  padding: 0;
}
.dgp-pricing-table-bundle-size,
.dgp-pricing-table-bundle-price {
  text-align: center;
}

.dgp-pricing-table-bundle-size {
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
}

.dgp-pricing-table-bundle-size-light {
  color: #7e93a7;
}

.dgp-pricing-table-bundle-size-essential {
  color: #e03c31;
}

.dgp-pricing-table-bundle-size-professional {
  color: #b12f34;
}

.dgp-pricing-table-bundle-price {
  font-size: 14px;
  font-weight: bold;
}

.dgp-pricing-table-bundle-price .dgp-pricing-table-price-indicator {
  font-size: 24px;
  line-height: 29px;
}
.is-capitalized {
  text-transform: capitalize;
}
</style>
