<template>
  <component :is="widgetComponent" />
</template>

<script>
import InfosecPricingWidget from "../components/InfosecPricingWidget.vue";
import PrivacyPricingWidget from "../components/PrivacyPricingWidget.vue";
import WhistleblowingPricingWidget from "../components/WhistleblowingPricingWidget.vue";
import CompliancePricingWidget from "../components/CompliancePricingWidget.vue";

export default {
  name: "PricingWidget",
  props: {
    selectedPackage: {
      type: String,
      validator: value => ["privacy", "infosec", "whistleblowing", "compliance"].includes(value),
    },
  },
  components: { InfosecPricingWidget, PrivacyPricingWidget, WhistleblowingPricingWidget, CompliancePricingWidget },
  computed: {
    widgetComponent() {
      return `${this.selectedPackage}-pricing-widget`;
    },
  },
};
</script>
